import { TabsMenu as TabsMenuUI } from "artisn-ui-react";
import MovieSVG from "images/TabsMenu/movie.svg";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./MoviesTabsMenuItem.styles";
import { MoviesTabsMenuItemProps as Props } from "./MoviesTabsMenuItem.types";
import useAnalytics from "hooks/analytics/useAnalytics";
import useI18n from "hooks/useI18n";

const { Item } = TabsMenuUI;

const MoviesTabsMenuItem: React.FC<Props> = props => {
  const { className, hideTitle, active } = props;
  const { push } = useRouter();

  const analyticsHook = useAnalytics({});
  const t = useI18n();

  const { logSelectTab } = analyticsHook;

  const onClickHandler = async () => {
    logSelectTab({
      name: "billboard"
    });
    await push("/billboard");
  };

  return (
    <Styles className={`MoviesTabsMenuItem ${className}`} active={active}>
      <Item
        className="MoviesTabsMenuItem__item"
        icon={MovieSVG}
        title={hideTitle ? undefined : t.navigation.movies}
        onClick={onClickHandler}
      />
    </Styles>
  );
};

MoviesTabsMenuItem.defaultProps = {
  className: ""
};

export default MoviesTabsMenuItem;
