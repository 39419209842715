import { useCountriesStore } from "@multicines/stores";
import { useMoviesStore } from "@multicines/stores";
import { CONSTANTS, getCalendarDays, titleCase } from "@multicines/utils";
import { Button, Dropdown } from "artisn-ui-react";
import dayjs, { Dayjs } from "dayjs";
import ArrowToDown from "images/arrow-left-small.svg";
import React, { useEffect, useMemo, useState } from "react";

import Styles, { SelectDateDropdownStyles } from "./SelectDate.styles";
import { SelectDateProps as Props } from "./SelectDate.types";
import Calendar from "../Calendar/Calendar";
import useAnalytics from "hooks/analytics/useAnalytics";
import useI18n from "hooks/useI18n";
import "dayjs/locale/es";

const { DAYS } = CONSTANTS.DATE;

const SelectDate: React.FC<Props> = props => {
  const analyticsHook = useAnalytics({});
  const days = useMemo(() => getCalendarDays(DAYS), []);
  const globalDate = useMoviesStore(store => store.globalDate);
  const setGlobalDate = useMoviesStore(store => store.setGlobalDate);
  const [dateText, setDateText] = useState("");
  const [index, setIndex] = useState(0);
  const t = useI18n();
  const selectedCountry = useCountriesStore(state => state.selectedCountry);

  const { logSelectDate } = analyticsHook;

  const { locale } = selectedCountry;
  const day = useMemo(() => {
    return dayjs(days[index]);
  }, [days, index]);
  const today = t.movie.today;
  const dateNumber = day.format("DD");
  const nextDay = `${day
    .locale(locale)
    .format("ddd")
    .replace(".", "")} ${dateNumber}`;

  const totalDates = useMemo(() => {
    const dates = new Array(DAYS)
      .fill(0)
      .map((_, index) => dayjs().add(index, "day"));
    const datesArray: Dayjs[][] = [];
    let index = 0;
    datesArray[index] = [];
    let firstDate = dates[0];
    dates.forEach(date => {
      if (!firstDate?.isSame(date, "month")) {
        firstDate = date;
        index = index + 1;
        datesArray[index] = [];
      }
      datesArray?.[index]?.push(date);
    });
    return datesArray;
  }, []);

  const dateChangeHandler = (date: Dayjs) => {
    setGlobalDate(date);
    logSelectDate();
  };

  useEffect(() => {
    const newIndex = days.findIndex(
      day => day.format("D") === globalDate?.format("D")
    );
    if (newIndex < 0) return;
    setIndex(newIndex);
    setDateText(newIndex === 0 ? today : nextDay);
  }, [dateText, days, index, nextDay, globalDate, today]);

  const calendarNode = (
    <SelectDateDropdownStyles className="calendar">
      <div className="container">
        {totalDates.map((date, index) => {
          return (
            <Calendar
              key={index}
              onChange={dateChangeHandler}
              initialDate={date[0]}
              dayAmount={date.length}
              selectedDate={globalDate}
            />
          );
        })}
      </div>
    </SelectDateDropdownStyles>
  );

  return (
    <Styles className="SelectDate">
      <Dropdown
        className="SelectDate__dropdown"
        overlay={calendarNode}
        target={["click"]}
        width={0}
      >
        <Button>
          <p className="SelectDate__dropdown__name">
            {dateText ? titleCase(dateText) : today}
          </p>
          <ArrowToDown className="SelectDate__top__icon" />
        </Button>
      </Dropdown>
    </Styles>
  );
};

SelectDate.defaultProps = {};

export default SelectDate;
