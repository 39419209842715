import styled from "styled-components";

import { LogoStyledProps as Props } from "./Logo.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const LogoStyled = styled.div<Props>`
  .Logo {
    &__no-desktop {
      display: none;

      @media (max-width: ${tablet - 1}px) {
        display: flex;
        gap: 0.5rem;
      }
    }

    &__desktop {
      display: flex;
      align-items: center;

      @media (max-width: ${tablet - 1}px) {
        display: none;
      }
    }

    &__separator {
      width: 0.1rem;
      height: 2rem;
      margin-left: 0.6rem;
      background-color: var(--palette-gray-s0-l40-a40);
    }
  }
`;

export default LogoStyled;
