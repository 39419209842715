import { TabsMenu as TabsMenuUI } from "artisn-ui-react";
import MulticlubSVG from "images/TabsMenu/multiclub.svg";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./MulticlubTabsMenuItem.styles";
import { MulticlubTabsMenuItemProps as Props } from "./MulticlubTabsMenuItem.types";
import useAnalytics from "hooks/analytics/useAnalytics";
import useI18n from "hooks/useI18n";
import { useAuthStore } from "stores/auth/auth.store";

const { Item } = TabsMenuUI;

const MulticlubTabsMenuItem: React.FC<Props> = props => {
  const { className, hideTitle, active } = props;
  const { push } = useRouter();
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const analyticsHook = useAnalytics({});
  const t = useI18n();

  const { logSelectTab } = analyticsHook;

  const onClickHandler = async () => {
    logSelectTab({
      name: "multiclub"
    });

    if (isAnonymous) {
      await push("/signin");
    } else {
      await push("/profile/membership");
    }
  };

  return (
    <Styles className={`MulticlubTabsMenuItem ${className}`} active={active}>
      <Item
        icon={MulticlubSVG}
        title={hideTitle ? undefined : t.navigation.multiclub}
        onClick={onClickHandler}
        className="MulticlubTabsMenuItem__item"
      />
    </Styles>
  );
};

MulticlubTabsMenuItem.defaultProps = {
  className: ""
};

export default MulticlubTabsMenuItem;
