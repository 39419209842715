import { useCountriesStore } from "@multicines/stores";
import { useMoviesStore } from "@multicines/stores";
import { Clickable } from "artisn-ui-react";
import dayjs, { Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "dayjs/locale/es";

import Styles from "./Calendar.styles";
import { CalendarProps as Props } from "./Calendar.types";

dayjs.extend(isoWeek);

const Calendar: React.FC<Props> = props => {
  const { className, onChange, initialDate = dayjs(), dayAmount } = props;
  const { selectedDate = dayjs() } = props;
  const [selectedDay, setSelectedDay] = useState(selectedDate);
  const selectedCountry = useCountriesStore(state => state.selectedCountry);
  const setGlobalDate = useMoviesStore(store => store.setGlobalDate);
  const { locale } = selectedCountry;

  const daysToRender = useMemo(() => {
    return new Array(dayAmount)
      .fill(0)
      .map((_, index) => initialDate.add(index, "day"));
  }, [dayAmount, initialDate]);

  const changeHandler = (date: Dayjs) => {
    setSelectedDay(date);
    setGlobalDate(date);
    onChange?.(date);
  };

  // TODO: implement real functionality
  // const upcomingReleasesHandler = () => {};

  const renderDaysName = () =>
    new Array(7).fill(0).map((_, index) => {
      const dayName = dayjs()
        .isoWeekday(++index)
        .locale(locale)
        .format("ddd")
        .replace(".", "");
      const sanitizedDayName =
        dayName.charAt(0).toUpperCase() + dayName.slice(1);

      return (
        <div className="Calendar__container-item" key={dayName}>
          <p className="Calendar__day-name">{sanitizedDayName}</p>
        </div>
      );
    });

  const renderWhiteSpaces = useCallback(() => {
    let i = 1;
    const calendarWhiteSpaces = [];
    while (
      daysToRender[0]!.format("dddd").toLowerCase() !==
      dayjs().isoWeekday(i).format("dddd").toLowerCase()
    ) {
      calendarWhiteSpaces.push("");
      i++;
    }

    return calendarWhiteSpaces.map((_, index) => (
      <div className="Calendar__container-item" key={index}>
        <div className="Calendar__day Calendar__white-space" />
      </div>
    ));
  }, [daysToRender]);

  useEffect(() => {
    setSelectedDay(selectedDate);
  }, [selectedDate]);

  return (
    <Styles className={`Calendar ${className}`}>
      <p className="Calendar__month">{`${daysToRender[0]
        ?.locale(locale)
        .format("MMMM")}`}</p>
      <div className="Calendar__days">
        {renderDaysName()}
        {renderWhiteSpaces()}
        {daysToRender.map(date => {
          const day = date.format("YYYY-MM-DD");
          const isSelected = day === selectedDay.format("YYYY-MM-DD");
          const touchableStyles = isSelected
            ? "Calendar__day Calendar__selected-day"
            : "Calendar__day";
          const contentStyles = isSelected
            ? "Calendar__day-content Calendar__selected-content"
            : "Calendar__day-content";

          return (
            <div className="Calendar__container-item" key={date.toISOString()}>
              <Clickable
                className={touchableStyles}
                onClick={() => changeHandler(date)}
              >
                <p className={contentStyles}>{date.format("D")}</p>
              </Clickable>
            </div>
          );
        })}
      </div>
    </Styles>
  );
};

Calendar.defaultProps = {
  className: ""
};

export default Calendar;
