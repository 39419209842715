import { useCataloguesStore } from "@multicines/stores";
import { events } from "artisn/analytics";
import { ButtonGroup } from "artisn-ui-react";
import DeliverySVG from "images/delivery.svg";
import PickupSVG from "images/pickup.svg";
import React from "react";

import Styles from "./ChooseWorkflowSwitch.styles";
import { ChooseWorkflowSwitchProps as Props } from "./ChooseWorkflowSwitch.types";
import CONSTANTS from "config/constants";
import useAnalytics from "hooks/analytics/useAnalytics";
import useTalkShop from "hooks/useTalkShop";
import { Workflow } from "types/workflow.types";

const { Button } = ButtonGroup;
const { logUpdateWorkflow } = events.workflow;
const { WITH_MULTIPLE_PURCHASE_METHODS } = CONSTANTS.FEATURE_FLAGS;
const { CATALOGUES } = CONSTANTS.ARTISN;
const [DELIVERY, PICK_UP] = CATALOGUES;

const ChooseWorkflowSwitch: React.FC<Props> = props => {
  const { className, onChange } = props;
  const { isTalkShop } = useTalkShop();
  const analyticsHook = useAnalytics({
    isTalkShop
  });
  const selectedCatalogue = useCataloguesStore(
    state => state.selectedCatalogue
  );
  const setSelectedCatalogueId = useCataloguesStore(
    state => state.setSelectedCatalogueId
  );

  const { commonParams } = analyticsHook;

  const { name: catalogueName } = selectedCatalogue;

  const changeHandler = (e: React.ChangeEvent<any>) => {
    const catalogueId = e.target.value;
    const catalogue = CATALOGUES.find(item => item.catalogueId === catalogueId);

    if (!catalogue) {
      throw new Error(
        `"${catalogueId}" does not correspond to a valid catalogue ID`
      );
    }

    logUpdateWorkflow({
      previousWorkflow: catalogueName as Workflow,
      nextWorkflow: catalogue.name as Workflow,
      ...commonParams
    });

    setSelectedCatalogueId(catalogueId);
    onChange?.();
  };

  if (!WITH_MULTIPLE_PURCHASE_METHODS) return null;

  return (
    <Styles
      className={`ChooseWorkflowSwitch ${className}`}
      defaultActive={catalogueName}
      onChange={changeHandler}
    >
      <Button
        className="ChooseWorkflowSwitch__button"
        id={DELIVERY.name}
        value={DELIVERY.catalogueId}
      >
        <DeliverySVG viewBox="0 0 16 16" />
        <p className="ChooseWorkflowSwitch__button__text">Domicilio</p>
      </Button>
      <Button
        className="ChooseWorkflowSwitch__button"
        id={PICK_UP.name}
        value={PICK_UP.catalogueId}
      >
        <PickupSVG />
        <p className="ChooseWorkflowSwitch__button__text">Pickup</p>
      </Button>
    </Styles>
  );
};

ChooseWorkflowSwitch.defaultProps = {
  className: ""
};

export default ChooseWorkflowSwitch;
