import styled from "styled-components";

import { CouponsTabsMenuItemStyledProps as Props } from "./CouponsTabsMenuItem.types";

const CouponsTabsMenuItemStyled = styled.div<Props>`
  & path {
    fill: ${props => (props.active ? "var(--palette-primary)" : "")};
  }

  && .TabsMenuItem__title {
    color: ${props =>
      props.active ? "var(--palette-primary)" : "var(--palette-black-s0-l10)"};
  }

  .CouponsTabsMenuItem {
  }
`;

export default CouponsTabsMenuItemStyled;
