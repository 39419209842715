import styled from "styled-components";

import { SelectDateStyledProps as Props } from "./SelectDate.types";
import CONSTANTS from "config/constants";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

export const SelectDateStyled = styled.div<Props>`
  padding-right: 0.8rem;
  align-items: center;
  align-self: center;

  .SelectDate {
    &__calendar {
      background-color: var(--palette-dark-blue-s13-l11);
      width: max-content;
      padding: 1.6rem;
      border-radius: 1.6rem;
      margin-top: 1.6rem;

      @media (max-width: ${mobile - 100}px) {
        padding: 0.8rem;
      }
    }

    &__container {
      display: flex;
    }

    &__dropdown {
      align-self: center;

      &__container {
        display: flex;
      }

      .Button {
        background-color: unset;
        border: none;
        box-shadow: none;
        padding-left: 0.8rem;
        padding-right: 0.8rem;

        @media (max-width: ${mobile}px) {
          padding-left: 0.8rem;
          padding-right: 0.8rem;
        }
      }

      &__name {
        color: var(--palette-primary);
        font-weight: 600;
        font-size: 1.6rem;
      }
    }

    &__top {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const SelectDateDropdownStyles = styled.div<Props>`
  background-color: var(--palette-dark-blue-s13-l11);
  width: max-content;
  padding: 1.6rem;
  border-radius: 1.6rem;
  margin-top: 1.6rem;
  position: fixed;
  overflow: hidden;
  max-height: 40rem;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0;
  }

  .month {
    font-size: 2.4rem;
    font-weight: 600;
    color: var(--palette-white);
    padding-top: 1.6rem;
    padding-bottom: 2.4rem;
  }

  @media (max-width: ${tablet}px) {
    position: fixed;
    left: 24%;
  }

  @media (max-width: ${tablet - 1}px) {
    position: fixed;
    left: 8%;
  }

  @media (max-width: ${mobile}px) {
    position: fixed;
    left: 0;
  }
`;

export default SelectDateStyled;
