import { useFetchNearbyStores } from "@multicines/services";
import { useGeoStore } from "@multicines/stores";
import { useCataloguesStore } from "@multicines/stores";
import { Button, Dropdown, Backdrop } from "artisn-ui-react";
import ArrowDropdownSVG from "images/arrow-dropdown.svg";
import CloseSVG from "images/close.svg";
import DeliverySVG from "images/delivery.svg";
import GeoSVG from "images/geo.svg";
import MapSVG from "images/map.svg";
import PickupSVG from "images/pickup.svg";
import StoreSVG from "images/store.svg";
import React, { useEffect, useState } from "react";

import { mobileBackdropConfig } from "./SelectAddressDropdown.helpers";
import Styles, { DropdownOverlayStyled } from "./SelectAddressDropdown.styles";
import { SelectAddressDropdownProps as Props } from "./SelectAddressDropdown.types";
import ShippingAddressModal from "components/checkout/ShippingAddressModal/ShippingAddressModal";
import ChooseWorkflowSwitch from "components/global/ChooseWorkflowSwitch/ChooseWorkflowSwitch";
import Divider from "components/global/Divider/Divider";
import CONSTANTS from "config/constants";
import { useFetchGoogleAddressFromCoords } from "services/geo/geo.service.hooks";
import { useStoresStore } from "stores/stores/stores.store";
import { getCurrentPosition } from "utils/geo.utils";
import { createErrorNotification } from "utils/notifications.utils";

const { WITH_PURCHASE } = CONSTANTS.FEATURE_FLAGS;

const SelectAddressDropdown: React.FC<Props> = props => {
  const { className } = props;
  const [geoCodeAddress, setGeoCodeAddress] = useState<string | undefined>();
  const [openChooseAddress, setOpenChooseAddress] = useState(false);
  const [backdropIsOpen, setBackdropIsOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);
  const selectedCoordinates = useGeoStore(state => state.selectedCoordinates);
  const setSelectedCoordinates = useGeoStore(
    state => state.setSelectedCoordinates
  );
  const { refetch: refetchNearbyStores } = useFetchNearbyStores(undefined);
  const selectedCatalogue = useCataloguesStore(
    state => state.selectedCatalogue
  );
  const selectedStore = useStoresStore(store => store.selectedStore);
  const showStoreNotification = useStoresStore(
    store => store.showStoreNotification
  );
  const setShowStoreNotification = useStoresStore(
    store => store.setShowStoreNotification
  );
  const [ticketsStore] = selectedStore ?? [];
  const { storeName } = ticketsStore ?? {};
  const { name: selectedCatalogueName } = selectedCatalogue;
  const isDelivery = selectedCatalogueName === "Delivery";
  const { data: predictions, error } =
    useFetchGoogleAddressFromCoords(selectedCoordinates);
  const { message } = error ?? {};

  const useCurrentLocationHandler = () => {
    if (isDelivery) {
      getCurrentLocation();
      setBackdropIsOpen(false);
      return;
    }

    refetchNearbyStores();
    setBackdropIsOpen(false);
  };

  const chooseLocationHandler = () => {
    if (isDelivery) {
      setOpenChooseAddress(true);
      setBackdropIsOpen(false);
      return;
    }

    setBackdropIsOpen(false);
  };

  const getCurrentLocation = async () => {
    await getCurrentPosition(
      async position => {
        const { latitude, longitude } = position.coords;
        setSelectedCoordinates({ lat: latitude, lng: longitude });
      },
      error => {
        setGeoCodeAddress(error.message);
      }
    );
  };

  useEffect(() => {
    if (!predictions || !predictions.length) return;
    const { formatted_address } = predictions[0];
    setGeoCodeAddress(formatted_address);
  }, [predictions]);

  useEffect(() => {
    if (message) createErrorNotification(message);
  }, [message]);

  useEffect(() => {
    if (!showStoreNotification) {
      setShowDropdown(true);
      return;
    }
    setShowDropdown(false);
  }, [showStoreNotification]);

  const buttonNode = (
    <Button
      className="SelectAddressDropdown__button"
      onClick={() => setShowStoreNotification(false)}
    >
      {isDelivery ? (
        <DeliverySVG
          className="SelectAddressDropdown__icon"
          viewBox="0 0 16 12"
        />
      ) : (
        <PickupSVG
          className="SelectAddressDropdown__icon"
          viewBox="0 0 18 18"
        />
      )}
      <div className="SelectAddressDropdown__button-text-container">
        <p className="SelectAddressDropdown__button-text">
          {isDelivery ? "Entregar ahora en" : "Recoger ahora en"}
        </p>
        <p className="SelectAddressDropdown__button-text">
          {isDelivery
            ? geoCodeAddress || "Selecciona una dirección"
            : storeName || "Selecciona una tienda"}
        </p>
      </div>
      <ArrowDropdownSVG />
    </Button>
  );

  const overlayNode = (
    <DropdownOverlayStyled className="DropdownOverlay">
      <CloseSVG
        className="DropdownOverlay__close-icon"
        onClick={() => setBackdropIsOpen(false)}
      />
      <ChooseWorkflowSwitch
        className="DropdownOverlay__choose-workflow-desktop"
        onChange={() => setBackdropIsOpen(false)}
      />
      <Button
        className="DropdownOverlay__option DropdownOverlay__select-address"
        type="LINK"
        onClick={useCurrentLocationHandler}
      >
        <GeoSVG viewBox="0 0 24 24" />
        {isDelivery
          ? "Utilizar mi ubicación actual"
          : "Seleccionar tienda más cercana"}
      </Button>
      <Divider className="DropdownOverlay__divider" />
      <Button
        className="DropdownOverlay__option"
        type="LINK"
        onClick={chooseLocationHandler}
      >
        {isDelivery ? (
          <>
            <MapSVG viewBox="0 0 18 18" />
            Selecciona una dirección
          </>
        ) : (
          <>
            <StoreSVG viewBox="0 0 18 18" />
            Selecciona una tienda
          </>
        )}
      </Button>
    </DropdownOverlayStyled>
  );

  if (!WITH_PURCHASE) return null;

  return (
    <Styles className={`SelectAddressDropdown ${className}`}>
      <Dropdown
        className="SelectAddressDropdown__dropdown"
        overlay={showDropdown ? overlayNode : null}
        target={["click"]}
        position={"left"}
        isFixed
      >
        {buttonNode}
      </Dropdown>
      <div
        className="SelectAddressDropdown__button-container"
        onClick={() => setBackdropIsOpen(true)}
      >
        {buttonNode}
      </div>
      <Backdrop
        {...mobileBackdropConfig}
        opened={backdropIsOpen}
        onClick={() => setBackdropIsOpen(false)}
      >
        {overlayNode}
      </Backdrop>
      <ShippingAddressModal
        opened={openChooseAddress}
        onClose={() => setOpenChooseAddress(false)}
        locationOnly
      />
    </Styles>
  );
};

SelectAddressDropdown.defaultProps = {
  className: ""
};

export default SelectAddressDropdown;
