import styled from "styled-components";

import { ActiveTicketStyledProps as Props } from "./ActiveTicket.types";
import CONSTANTS from "config/constants";

const { tablet, wide } = CONSTANTS.BREAKPOINTS;

const imageWidth = "6.2rem";
const imageHeight = "8.5rem";
const containerHeight = "0.8rem";

const ActiveTicketStyled = styled.div<Props>`
  width: 100%;
  height: 100%;
  align-items: center;
  padding: ${props => (props.show ? "0 1rem" : "0")};
  display: ${props => (props.talkShop ? "none" : "flex")};

  @media (min-width: ${wide}px) {
    padding: ${props => (props.show ? "0 1.6rem" : "0")};
  }

  @media (max-width: ${tablet}px) {
    padding-left: 0;
    padding-right: 0;
  }

  .ActiveTicket {
    &__normal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-14), translateY(-20);
    }

    &__menu {
      cursor: pointer;
    }

    &__background {
      width: max-content;
      position: absolute;
      left: 14%;
      transform: translateX(calc(-${imageWidth} / 2));
    }

    &__container {
      flex: 1;
      position: relative;
      width: 100%;
      top: -4rem;
    }

    &__content {
      margin-top: ${containerHeight};
      width: ${imageWidth};
      height: ${imageHeight};
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(calc(-${imageWidth} / 2));
      align-items: center;
      padding: 0.8rem 0;
      text-align: center;
      cursor: pointer;
    }

    &__day {
      font-size: 1.4rem;
      color: var(--palette-mid-blue-s15-l8);
    }

    &__time {
      font-weight: 600;
      font-size: 1.6rem;
      color: var(--palette-mid-blue-s15-l8);
    }
  }
`;

export default ActiveTicketStyled;
