import { useFetchRecommendedProducts } from "@multicines/services";
import { events } from "artisn/analytics";
import { Product } from "artisn/types";
import { SearchBar, useSearchBar, Image } from "artisn-ui-react";
import ClockSVG from "images/clock.svg";
import { useRouter } from "next/router";
import React, { useState } from "react";

import { imageConfig } from "./SearchBarProducts.helpers";
import Styles from "./SearchBarProducts.styles";
import { SearchBarContentStyled as ContentStyles } from "./SearchBarProducts.styles";
import { SearchBarProductsProps as Props } from "./SearchBarProducts.types";
import useAnalytics from "hooks/analytics/useAnalytics";
import { useAuthStore } from "stores/auth/auth.store";
import { dropdownConfigs } from "utils/common.utils";
import { goToProductDetail } from "utils/seo.utils";

const { Item } = SearchBar;
const { logSearchProductAttempt } = events.product;

const SearchBarProducts: React.FC<Props> = props => {
  const { className } = props;
  const router = useRouter();
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const analyticsHook = useAnalytics({});
  const { data } = useFetchRecommendedProducts({ isAnonymous, uid });
  const [showSearchBarContent, setShowSearchBarContent] = useState(false);

  const { commonParams } = analyticsHook;

  const instanceSearchBar = useSearchBar({
    name: "principal-searchBar"
  });

  const { cleanSearchHistory, recentSearches } = instanceSearchBar;

  const onClickItem = (product: Product) => {
    goToProductDetail(product);
  };

  const onClick = () => {
    setShowSearchBarContent(true);
  };

  const onClickCancel = () => {
    setShowSearchBarContent(false);
  };

  const onSubmit = (query: string) => {
    logSearchProductAttempt({
      query,
      ...commonParams
    });

    router.push({
      pathname: "/products",
      query: { q: query }
    });
  };

  const backdropConfig = {
    onClick: onClickCancel
  };

  return (
    <Styles className={`SearchBarProducts ${className}`}>
      <SearchBar
        className="SearchBarProducts__search-bar field"
        placeholder="Buscar"
        onSubmit={onSubmit}
        instance={instanceSearchBar}
        onInputClick={onClick}
        onInputBlur={onClickCancel}
        onClear={() => setShowSearchBarContent(false)}
        dropdownConfigs={{
          ...dropdownConfigs,
          showDropdown: showSearchBarContent,
          position: "center"
        }}
        backdropConfig={backdropConfig}
      >
        <ContentStyles className="SearchBarContent">
          <div className="SearchBarContent__recent-searches-container">
            <div className="SearchBarContent__section-info">
              <h3 className="SearchBarContent__section-title">
                Búsquedas recientes
              </h3>
              <span
                onClick={cleanSearchHistory}
                className="SearchBarContent__clean-button"
              >
                Limpiar historial
              </span>
            </div>
            {recentSearches.length ? (
              <div className="SearchBarContent__recent-searches-item-container">
                {recentSearches.map((item, index) => (
                  <Item
                    key={index}
                    className="SearchBarContent__recent-searches-item"
                    query={item}
                  >
                    <p className="SearchBarContent__recent-searches-query">
                      {item}
                    </p>
                    <ClockSVG />
                  </Item>
                ))}
              </div>
            ) : null}
          </div>
          <div className="SearchBarContent__content-container">
            <div className="SearchBarContent__section-info">
              <h3 className="SearchBarContent__section-title SearchBarContent__section-padding">
                Búsquedas populares
              </h3>
            </div>
            {data?.map((item, index) => {
              const { name, images } = item;

              return (
                <Item
                  key={index}
                  className="SearchBarContent__dropdown-item"
                  onClick={() => onClickItem(item)}
                  query={name}
                >
                  <Image
                    alt={name}
                    image={images[0]}
                    config={imageConfig}
                    placeholder="blur"
                  />
                  <div className="SearchBarContent__item-info">
                    <p className="SearchBarContent__item-name">{name}</p>
                    <p className="SearchBarContent__item-number">
                      {data.length} resultados
                    </p>
                  </div>
                </Item>
              );
            })}
          </div>
        </ContentStyles>
      </SearchBar>
    </Styles>
  );
};

SearchBarProducts.defaultProps = {
  className: ""
};

export default SearchBarProducts;
