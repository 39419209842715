import styled from "styled-components";

import { IceTabsMenuItemStyledProps as Props } from "./IceTabsMenuItem.types";

const IceTabsMenuItemStyled = styled.div<Props>`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -0.8rem;
  cursor: pointer;

  .IceTabsMenuItem {
    &__item {
      font-weight: 600;
      line-height: 0.8rem;
    }

    &__title {
      color: ${props =>
        props.active ? "var(--palette-primary)" : "var(--palette-white)"};
      padding-top: 0.48rem;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 0.8rem;
      white-space: nowrap;
    }
  }
`;

export default IceTabsMenuItemStyled;
