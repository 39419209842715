import { Image } from "artisn-ui-react";
import React from "react";

import Styles from "./IceTabsMenuItem.styles";
import { IceTabsMenuItemProps as Props } from "./IceTabsMenuItem.types";

const IceTabsMenuItem: React.FC<Props> = props => {
  const { className, hideTitle, active } = props;

  const onClickHandler = async () => {
    window.open("https://multicines-ice.webflow.io/", "_blank");
  };

  return (
    <Styles
      className={`IceTabsMenuItem ${className}`}
      active={active}
      onClick={onClickHandler}
    >
      <Image
        image="/assets/images/TabsMenu/ice-button-2.png"
        alt="background"
        width={58}
        height={31}
      />
      {!hideTitle ? (
        <p className={`IceTabsMenuItem__title`}>ICE Theaters</p>
      ) : null}
    </Styles>
  );
};

export default IceTabsMenuItem;
