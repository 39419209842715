import styled from "styled-components";

import { CinemaAndDayStyledProps as Props } from "./CinemaAndDay.types";
import CONSTANTS from "config/constants";

const { desktop, tablet, mobile } = CONSTANTS.BREAKPOINTS;

const CinemaAndDayStyled = styled.div<Props>`
  display: inline-flex;
  padding: 0.4rem 0;

  @media (max-width: ${tablet}px) {
    background-color: var(--palette-mid-blue-s13-l18);
    border-radius: 0.4rem;
  }

  .CinemaAndDay {
    &__store {
      background-color: unset;
      border: none;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }

      @media (max-width: ${mobile}px) {
        padding-left: 0.4rem;
        padding-right: 0;
      }
    }

    &__top {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      &__brand {
        &.not-home {
          @media (max-width: ${tablet}px) {
            display: none;
          }
        }
      }

      &__separator {
        color: var(--palette-gray-s0-l30);
        padding-right: 0.8rem;
        align-self: center;
        /*
        @media (max-width: ${desktop - 1}px) {
          display: none;
        } */

        @media (max-width: ${tablet}px) {
          padding-left: 0.8rem;
        }

        @media (max-width: ${mobile}px) {
          padding-left: 0;
        }
      }

      &__icon {
        @media (max-width: ${mobile}px) {
          margin-right: 0.8rem;
        }
      }

      &__text {
        color: var(--palette-white);
        margin: 0 0.4rem;
        white-space: nowrap;
        width: 12.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.6rem;
        font-weight: 600;

        @media (max-width: ${desktop}px) {
          width: ${props => (props.isAnonymous ? "9.6rem" : "12.6rem")};
        }

        @media (max-width: ${tablet - 1}px) {
          margin-right: 0.8rem;
          width: 8rem;
        }

        @media (max-width: ${mobile - 100}px) {
          margin: 0;
          width: 4.8rem;
        }
      }

      &__buttons {
        flex: right;
        z-index: 1;

        @media (max-width: ${tablet}px) {
          display: none;
        }
      }

      &__buttons > *:not(:last-child) {
        margin-right: 3.2rem;
      }

      &__responsive {
        justify-content: space-between;
        display: none;

        &.not-home {
          @media (max-width: ${tablet}px) {
            display: flex;
          }
        }
      }
    }
  }
`;

export default CinemaAndDayStyled;
