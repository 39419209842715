import styled from "styled-components";

import { NavbarStyledProps as Props } from "./Navbar.types";
import CONSTANTS from "config/constants";

const { desktop, tablet, mobile } = CONSTANTS.BREAKPOINTS;
const { WITH_SELECT_ADDRESS_DROPDOWN } = CONSTANTS.FEATURE_FLAGS;

const NavbarStyled = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: var(--sizes-navbar-height);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  align-items: center;
  grid-template-areas: ". content .";
  background-color: var(--palette-mid-blue-s15-l8);

  @media (max-width: ${tablet}px) {
    height: ${props =>
      props.showBottomSearch
        ? "var(--sizes-navbar-height-mobile)"
        : "var(--sizes-navbar-height-mobile-without-search)"};
    padding-top: env(safe-area-inset-top);
    padding-bottom: 0.8rem;
  }

  .Navbar {
    &__logo {
      padding-left: 0.8rem;

      @media (max-width: ${tablet - 1}px) {
        padding-left: 0;
      }
    }

    &__no-desktop {
      display: inline-flex;
      padding: 0.4rem 0;
    }

    &__content {
      grid-area: content;
    }

    &__icon {
      display: flex;
      align-items: center;
    }

    &__dropdown {
      flex-shrink: 0;

      @media (max-width: ${tablet}px) {
        flex-shrink: 1;
      }

      @media (max-width: ${mobile}px) {
        margin-left: 0;
        flex-shrink: 1;
      }
    }

    &__store {
      background-color: unset;
      border: none;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }

      @media (max-width: ${mobile}px) {
        padding-left: 0.4rem;
        padding-right: 0;
      }
    }

    &__cinema {
      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__top {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      &__brand {
        &.not-home {
          @media (max-width: ${tablet}px) {
            display: none;
          }
        }
      }

      &__separator {
        color: var(--palette-gray-s0-l30);
        padding-right: 0.8rem;
        align-self: center;
        /*
        @media (max-width: ${desktop - 1}px) {
          display: none;
        } */

        @media (max-width: ${tablet}px) {
          padding-left: 0.8rem;
        }

        @media (max-width: ${mobile}px) {
          padding-left: 0;
        }
      }

      &__icon {
        @media (max-width: ${mobile}px) {
          margin-right: 0.8rem;
        }
      }

      &__text {
        color: var(--palette-white);
        margin: 0 0.4rem;
        white-space: nowrap;
        width: 12.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.6rem;
        font-weight: 600;

        @media (max-width: ${desktop}px) {
          width: ${props => (props.isAnonymous ? "9.6rem" : "12.6rem")};
        }

        @media (max-width: ${tablet - 1}px) {
          margin-right: 0.8rem;
          width: 8rem;
        }

        @media (max-width: ${mobile - 100}px) {
          margin: 0;
          width: 4.8rem;
        }
      }

      &__buttons {
        flex: right;
        z-index: 1;

        @media (max-width: ${tablet}px) {
          display: none;
        }
      }

      &__buttons > *:not(:last-child) {
        margin-right: 3.2rem;
      }

      &__responsive {
        justify-content: space-between;
        display: none;

        &.not-home {
          @media (max-width: ${tablet}px) {
            display: flex;
          }
        }
      }
    }

    &__title {
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--palette-black-s0-l10);
    }

    &__back {
      visibility: ${props => (props.talkShop ? "hidden" : "visible")};
    }

    &__bottom {
      display: none;
      flex-wrap: nowrap;
      align-items: center;

      @media (max-width: ${tablet}px) {
        display: flex;
      }
    }

    &__search-bar {
      max-width: 60rem;
      width: 100%;
      margin-left: ${WITH_SELECT_ADDRESS_DROPDOWN ? "2.4rem" : "auto"};
      margin-right: 2.4rem;

      @media (max-width: ${tablet}px) {
        margin-left: 2.4rem;
      }

      &__center {
        margin: 0;
        max-width: 60rem;
        padding: 0 2rem;
        border-radius: 0.8rem;
        overflow: hidden;

        .SearchBar__input {
          max-width: 19.2rem;
        }

        @media (max-width: 360px) {
          padding: 0;
          max-width: 22rem;

          .SearchBar__input {
            max-width: 15.2rem;
          }
        }
      }

      &__bottom {
        margin: 0;
        padding-top: 0.8rem;
        max-width: none;
      }
    }

    &__btn {
      &__loyalty {
      }

      &__login {
        display: ${props => (props.talkShop ? "none" : "flex")};
        padding: 1rem 2rem;
      }

      &__coupons {
        cursor: pointer;

        & path {
          fill: var(--palette-black-s0-l10);
        }
      }

      &__profile {
        margin-left: auto;
        cursor: pointer;

        & path {
          fill: var(--palette-white);
        }
      }

      &__snacks {
        margin-left: auto;
        cursor: pointer;
        display: ${props => (props.talkShop ? "none" : "flex")};

        & path {
          fill: var(--palette-white);
        }
      }

      &__play {
        flex-direction: row;
        color: var(--palette-white);
        padding: 1.2rem 2rem;
        background-color: var(--palette-gray-s0-l40-a40);
        border: unset;
        box-shadow: unset;
        border-radius: 1.2rem;
        font-weight: 700;
        line-height: 0.8rem;
        margin-left: auto;
        cursor: pointer;
        display: ${props => (props.talkShop ? "none" : "flex")};

        & path {
          fill: var(--palette-white);
        }

        & > span {
          width: max-content;
        }
      }

      &__movies {
        margin-left: auto;
        cursor: pointer;
        display: ${props => (props.talkShop ? "none" : "flex")};

        & path {
          fill: var(--palette-white);
        }
      }

      &__settings {
        @media (max-width: ${tablet}px) {
          margin-left: auto;
        }
      }

      &__cart {
        display: ${props => (props.talkShop ? "none" : "flex")};

        @media (max-width: ${tablet}px) {
          position: absolute;
          right: 1.6rem;
          margin-left: ${WITH_SELECT_ADDRESS_DROPDOWN ? "auto" : "initial"};
        }
      }

      &__multiclub {
        display: ${props => (props.talkShop ? "none" : "flex")};
      }

      &__hidden {
        visibility: hidden;
      }
    }

    &__cart {
      &__desktop {
        display: none;

        @media (max-width: ${tablet}px) {
          display: ${props => (props.talkShop ? "none" : "flex")};
        }
      }

      &__mobile {
        display: ${props => (props.talkShop ? "none" : "flex")};

        @media (max-width: ${tablet}px) {
          display: none;
        }
      }
    }

    &__search-bar--top,
    &__btn--loyalty,
    &__btn--coupons,
    &__btn--profile {
      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__divider {
      width: 0.15rem;
      height: 4.4rem;
      background-color: var(--palette-gray-s0-l40-a40);
    }
  }
`;

export default NavbarStyled;
