import { TabsMenu as TabsMenuUI } from "artisn-ui-react";
import SnacksSVG from "images/TabsMenu/popcorn.svg";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./SnacksTabsMenuItem.styles";
import { SnacksTabsMenuItemProps as Props } from "./SnacksTabsMenuItem.types";
import useAnalytics from "hooks/analytics/useAnalytics";
import useI18n from "hooks/useI18n";

const { Item } = TabsMenuUI;

const SnacksTabsMenuItem: React.FC<Props> = props => {
  const { className, hideTitle, active } = props;
  const { push } = useRouter();

  const analyticsHook = useAnalytics({
    isSnackWorkflow: true
  });
  const t = useI18n();

  const { logSelectTab } = analyticsHook;

  const onClickHandler = async () => {
    logSelectTab({
      name: "snacks"
    });
    await push("/categories");
  };

  return (
    <Styles className={`SnacksTabsMenuItem ${className}`} active={active}>
      <Item
        className="SnacksTabsMenuItem__item"
        icon={SnacksSVG}
        title={hideTitle ? undefined : t.navigation.snacks}
        onClick={onClickHandler}
      />
    </Styles>
  );
};

SnacksTabsMenuItem.defaultProps = {
  className: ""
};

export default SnacksTabsMenuItem;
