import styled from "styled-components";

import { MulticlubTabsMenuItemStyledProps as Props } from "./MulticlubTabsMenuItem.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const MulticlubTabsMenuItemStyled = styled.div<Props>`
  && .TabsMenuItem__title {
    color: ${props =>
      props.active ? "var(--palette-primary)" : "var(--palette-white)"};

    @media (max-width: ${tablet}px) {
      padding-left: 0;
      color: ${props =>
        props.active
          ? "var(--palette-primary)"
          : "var(--palette-light-blue-s10-l60)"};
    }
  }

  .MulticlubTabsMenuItem {
    &__item {
      flex-direction: column;
      color: var(--palette-white);
      border-radius: 1.2rem;
      font-weight: 700;
      line-height: 0.8rem;

      & path {
        fill: ${props =>
          props.active ? "var(--palette-primary)" : "var(--palette-white)"};
      }

      @media (max-width: ${tablet}px) {
        background-color: transparent;
        flex-direction: column;
        font-weight: 400;
        line-height: 1.2rem;
        padding: 0;

        path {
          fill: ${props =>
            props.active
              ? "var(--palette-primary)"
              : "var(--palette-light-blue-s10-l60)"};
        }
      }
    }
  }
`;

export default MulticlubTabsMenuItemStyled;
