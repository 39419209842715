import { useFetchMovies, useFetchOrderHistory } from "@multicines/services";
import { useCataloguesStore } from "@multicines/stores";
import { getDate, getActiveTicketOrders, titleCase } from "@multicines/utils";
import { Image } from "artisn-ui-react";
import dayjs from "dayjs";
import QRSVG from "images/qr.svg";
import { useRouter } from "next/router";
import React, { useEffect, useMemo } from "react";
import { animated, config, useSpring } from "react-spring";

import Styles from "./ActiveTicket.styles";
import { ActiveTicketProps as Props } from "./ActiveTicket.types";
import UserTabsMenuItem from "../navigation/UserTabsMenuItem/UserTabsMenuItem";
import useTalkShop from "hooks/useTalkShop";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";

const ActiveTicket: React.FC<Props> = props => {
  const { className, active } = props;
  const { isTalkShop } = useTalkShop();
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const { push } = useRouter();
  const selectedStore = useStoresStore(store => store.selectedStore);
  const { data: movies } = useFetchMovies({ isAnonymous, uid }, selectedStore);
  const selectedCatalogueId = useCataloguesStore(
    state => state.selectedCatalogue.catalogueId
  );

  const { data: orders } = useFetchOrderHistory(
    { isAnonymous, uid },
    selectedCatalogueId,
    "DONE"
  );

  const activeOrder = useMemo(() => {
    const [firstActiveOrder] = getActiveTicketOrders(orders) ?? [];
    return firstActiveOrder;
  }, [orders]);
  const { showtime } = activeOrder?.additionalInfo?.session ?? {};
  const show = !!activeOrder && !!movies;
  const [animationStyles, api] = useSpring(() => {
    return {
      from: { translateY: 0 },
      delay: 1000,
      config: config.stiff
    };
  }, [show]);

  useEffect(() => {
    if (!show) {
      api.start({ translateY: 0 });
      return;
    }
    api.start({ translateY: 0 });
  }, [show, api, movies]);

  return (
    <Styles className="ActiveTicket" show={show} talkShop={isTalkShop}>
      {show ? (
        <animated.div
          className={`ActiveTicket__container ${className}`}
          onClick={() => push("/profile/tickets")}
          style={animationStyles}
        >
          <Image
            className="ActiveTicket__background"
            image="../../../assets/images/ticket.png"
            alt="background"
          />
          <div className="ActiveTicket__content">
            <QRSVG />
            <p className="ActiveTicket__day">{titleCase(getDate(showtime!))}</p>
            <p className="ActiveTicket__time">
              {dayjs(showtime).format("HH:mm")}
            </p>
          </div>
        </animated.div>
      ) : (
        <UserTabsMenuItem
          className={`ActiveTicket__menu ${className}`}
          hideTitle={false}
          active={active ?? false}
        />
      )}
    </Styles>
  );
};

ActiveTicket.defaultProps = {};

export default ActiveTicket;
