import { ButtonGroup, ButtonGroupProps as Props } from "artisn-ui-react";
import styled from "styled-components";

const ChooseWorkflowSwitchStyled = styled(ButtonGroup)<Props>`
  display: flex;
  height: 4.8rem;
  padding: 0.4rem;
  border-radius: 0.8rem;
  background-color: var(--palette-gray-s0-l98);
  flex-wrap: nowrap;

  .ChooseWorkflowSwitch {
    &__button {
      flex-grow: 1;
      width: 8.5rem;
      text-align: center;
      border-radius: 0.4rem;
      border: none;
      box-shadow: none;

      &:hover {
        box-shadow: none;
        color: var(--palette-black-s0-l20);
      }

      &__text {
        font-size: 1.4rem;
        font-weight: 800;
        color: var(--palette-black-s0-l20);
      }

      svg {
        flex-shrink: 0;

        path {
          fill: var(--palette-black-s0-l10);
        }
      }

      &.Button--active {
        background-color: var(--palette-black-s0-l10);
        color: var(--palette-white);
        border: none;

        svg {
          path {
            fill: var(--palette-white);
          }
        }
      }
    }
  }
`;

export default ChooseWorkflowSwitchStyled;
