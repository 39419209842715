import styled from "styled-components";

import { SnacksTabsMenuItemStyledProps as Props } from "./SnacksTabsMenuItem.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const SnacksTabsMenuItemStyled = styled.div<Props>`
  && .TabsMenuItem__title {
    color: ${props =>
      props.active ? "var(--palette-primary)" : "var(--palette-white)"};
    padding-top: 0.4rem;

    @media (max-width: ${tablet}px) {
      padding-top: 0.4rem;
      padding-left: 0;
      color: ${props =>
        props.active
          ? "var(--palette-primary)"
          : "var(--palette-light-blue-s10-l60)"};
    }
  }

  .SnacksTabsMenuItem {
    &__item {
      color: var(--palette-white);
      font-weight: 600;
      line-height: 0.8rem;

      & path {
        fill: ${props =>
          props.active ? "var(--palette-primary)" : "var(--palette-white)"};
      }

      @media (max-width: ${tablet}px) {
        flex-direction: column;
        font-weight: 400;
        line-height: 1.2rem;

        path {
          fill: ${props =>
            props.active
              ? "var(--palette-primary)"
              : "var(--palette-light-blue-s10-l60)"};
        }
      }
    }
  }
`;

export default SnacksTabsMenuItemStyled;
