import styled from "styled-components";

import { CalendarStyledProps as Props } from "./Calendar.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const CalendarStyled = styled.div<Props>`
  .Calendar {
    &__month {
      font-size: 2.4rem;
      font-weight: 600;
      color: var(--palette-white);
      padding-top: 1.6rem;
      padding-bottom: 2.4rem;
      text-transform: capitalize;
    }

    &__days {
      display: flex;
      flex-wrap: wrap;
      width: 32rem;

      @media (max-width: ${mobile - 100}px) {
        width: 30rem;
      }
    }

    &__day-name {
      text-align: center;
      font-size: 1.4rem;
      color: var(--palette-white);
    }

    &__container-item {
      width: 14.2%;
      padding: 0.4rem;
    }

    &__day {
      justify-content: center;
      align-items: center;
      border: solid 0.1rem var(--palette-mid-blue-s15-l20);
      aspect-ratio: 1 / 1;
      border-radius: 1rem;
      height: 4rem;
      width: 4rem;
    }

    &__white-space {
      border-color: var(--palette-mid-blue-s15-l20);
      background-color: var(--palette-mid-blue-s15-l20);
    }

    &__selected-day {
      border-color: var(--palette-primary);
      background-color: var(--palette-primary);
    }

    &__day-content {
      font-size: 1.4rem;
      color: var(--palette-white);
    }

    &__selected-content {
      color: var(--palette-mid-blue-s15-l8);
    }

    &__upcoming-releases {
      flex-direction: row;
      padding: 0.4rem;
    }

    &__upcoming-releases-text {
      padding: 1.2rem 1rem;
      height: 100%;
      text-align: center;
      border-width: 0.1rem;
      border-color: var(--palette-mid-blue-s15-l20);
      color: var(--palette-white);
      border-radius: 1rem;
    }
  }
`;

export default CalendarStyled;
