import styled from "styled-components";

import { FooterStyledProps as Props } from "./Footer.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const FooterStyled = styled.div<Props>`
  background-color: var(--palette-dark-blue-s13-l11);
  padding-bottom: 2.4rem;
  border-top: 0.1rem solid var(--palette-mid-blue-s15-l20);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-rows: max-content 1fr max-content;

  .Footer {
    &__content {
      grid-column: content;
      display: grid;
      grid-auto-flow: column;
      padding-top: 4rem;
      padding-bottom: 4rem;

      @media (max-width: ${tablet}px) {
        grid-auto-flow: row;
      }
    }

    &__logo-and-button {
      display: flex;
      flex-direction: column;
    }

    &__button {
      width: 16rem;
      background-color: var(--palette-mid-blue-s15-l20);
      color: var(--palette-yellow-s97-l54);
      font-weight: 700;
      margin-top: 1.2rem;
      padding: 1.2rem;
      border-radius: 0.8rem;
      border: none;

      @media (max-width: ${tablet}px) {
        margin-top: 3.2rem;
      }
    }

    &__name-and-social {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;

      @media (max-width: ${tablet}px) {
        width: 100%;
        flex-direction: row;
        padding-bottom: 3.2rem;
      }

      &__social {
        display: flex;
        padding-top: 2.4rem;

        @media (max-width: ${tablet}px) {
          padding-top: 0;
        }

        &__icon {
          display: flex;
          align-items: center;
          cursor: pointer;
          width: 3.2rem;

          & > * {
            fill: white;
          }
        }
      }
    }

    &__information {
      display: grid;
      grid-template-columns: auto auto;

      @media (max-width: ${tablet - 1}px) {
        grid-template-columns: auto;
      }

      &__column {
        max-width: 100%;

        @media (max-width: ${tablet - 1}px) {
          max-width: 88%;
        }

        @media (max-width: ${tablet - 1}px) {
          padding-bottom: 1.6rem;
          max-width: 100%;
        }
      }

      &__title {
        font-weight: 700;
        font-size: 1.6rem;
        color: var(--palette-white);
        line-height: 2rem;
      }

      &__item {
        cursor: pointer;
        color: var(--palette-light-blue-s10-l60);
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.6rem;
        padding: 0.8rem 0;

        & > a {
          text-decoration: none;
          color: inherit;
        }
      }
    }

    &__privacy-and-terms {
      display: flex;
      font-size: 1rem;

      &__item {
        text-decoration: none;
        cursor: pointer;
        text-align: center;
        color: var(--palette-light-blue-s10-l60);

        &:not(:last-child)::after {
          content: " | ";
        }

        &:not(:first-child) {
          padding-left: 0.2rem;
        }
      }
    }

    &__copyright {
      display: grid;
      grid-column: content;
      border-top: 0.1rem solid var(--palette-mid-blue-s15-l20);
      padding-top: 2.4rem;

      &-content {
        display: flex;
        justify-content: space-between;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: var(--palette-light-blue-s10-l60);

        span {
          color: var(--palette-white);
          font-weight: 600;
        }

        @media (max-width: ${tablet}px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          &-rights {
            padding-bottom: 1.6rem;
          }
        }
      }

      &__company {
        text-decoration: none;
        color: var(--palette-light-blue-s10-l60);
      }
    }
  }
`;

export default FooterStyled;
